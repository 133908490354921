<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="hero center">
          <div class="hero__title">Flesherton Welding Supply</div>
          <div class="hero__text">
            Your local ESAB dealer for all welding supplies in Flesherton, Dundalk, and Grey Highlands
          </div>
          <router-link to="/products" class="btn btn--bgr">View Products</router-link>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="categories-title">Main Categories</h3>
        <ul class="categories">
          <li v-for="cat in categories" :key="cat.name" class="categories__item">
            <router-link :to="{name: 'Products', params: {name: cat.name}}">
              <div class="categories__link">
                <div class="categories__img center">
                  <div class="categories__icon"></div>
                </div>
                <h3 class="categories__title">{{ cat.name }}</h3>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="section section--colored">
      <div class="container">
        <vue-agile :options="sliderOptions">
          <div class="slide">
            <div class="block">
              <div class="block__info">
                <h2 class="block__title">What we do.</h2>
                <p class="block__text">
                  Flesherton Welding Supply offers the full ESAB product line up and is an accredited dealer. We stock a
                  high volume of welding supplies and equipment and convenient and fast shipping is available across the
                  entire ESAB product list.
                </p>
                <router-link to="about" class="link">Learn more</router-link>
              </div>
              <div class="block__img-wrap">
                <img src="@/assets/images/LongLaneWeldingWireStacked.jpg" alt="welding-about" class="block__img" />
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="block">
              <div class="block__info">
                <h2 class="block__title">What we do.</h2>
                <p class="block__text">
                  Flesherton Welding Supply offers the full ESAB product line up and is an accredited dealer. We stock a
                  high volume of welding supplies and equipment and convenient and fast shipping is available across the
                  entire ESAB product list.
                </p>
                <router-link to="about" class="link">Learn more</router-link>
              </div>
              <div class="block__img-wrap">
                <img src="@/assets/images/LongLaneWeldingWireStacked.jpg" alt="welding-about" class="block__img" />
              </div>
            </div>
          </div>
          <div class="slide">
            <div class="block">
              <div class="block__info">
                <h2 class="block__title">What we do.</h2>
                <p class="block__text">
                  Longlane Industries is a world leader in the production of welding and cutting equipment and
                  consumables. Our innovative, world-renowned equipment and solutions are developed with input from our
                  customers and built with the expertise and heritage of a global manufacturing leader.
                </p>
                <router-link to="about" class="link">Learn more</router-link>
              </div>
              <div class="block__img-wrap">
                <img src="@/assets/images/LongLaneWeldingWireStacked.jpg" alt="welding-about" class="block__img" />
              </div>
            </div>
          </div>
          <!-- <template slot="prevButton"> <i class="ri-arrow-left-s-line"></i></template>
          <template slot="nextButton"> <i class="ri-arrow-right-s-line"></i></template> -->
        </vue-agile>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <ul class="brands">
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/tweco.png" alt="Long Lane  Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/alcotec.png" alt="Long Lane  Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/alcair.png" alt="Long Lane  Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/stoody.png" alt="Long Lane  Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img
                src="@/assets/images/thermal-dynamics.png"
                alt="Long Lane  Welding Supply Brands"
                class="brands__logo"
              />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/turbotorch.png" alt="Long Lane  Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
        </ul>
      </div>
    </section>
    <Form />
  </main>
</template>

<script>
import {VueAgile} from 'vue-agile'
import Form from '@/views/Customer/components/Form'
import {mapGetters} from 'vuex'

export default {
  name: 'Home',
  components: {
    Form,
    VueAgile
  },
  data() {
    return {
      sliderOptions: {
        dots: false,
        // navButtons: true,
        navButtons: false,
        infinite: false
      }
    }
  },
  computed: {
    ...mapGetters({
      categories: 'main/categories'
    })
  },
  metaInfo: {
    title: 'Home | Flesherton Welding Supply',
    meta: [
      {
        name: 'description',
        content:
          'Flesherton Welding Supply is your local ESAB dealer for Grey Highlands, Southgate, and Shelburne area. We stock a variety of equipment and welding supplies.'
      }
    ]
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
